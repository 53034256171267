.page-content{
  padding-bottom:60px;
}
.page-content{
  min-height: calc(100vh - 200px);
}
.page-container{
  padding-bottom:25px;
}
/* line 108, src/sass/layout/_layout.scss */
.page-container .page-btn {

  /* position: -webkit-fixed; */

}
.product-img img {
  object-fit: contain;
}
.page-heading{
  position:sticky;
  top:0;
}
.checkout-btn{
  flex:4;
}
.lang-en  .checkout-btn a.btn.btn-primary {
  text-align: left !important;
}

.lang-ar  .checkout-btn a.btn.btn-primary {
  text-align: right !important;
}

.lang-en .im.im-arrleft{
  -webkit-transform: initial ;
    transform: initial;
}
.lang-ar .im.im-arrleft{
  -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

/* .page.page-inner .page-container .page-btn{
  height: 89px;
} */
app-footer div {
  background: white;
  height: 20px;
  text-align: center;
}
/***** Menu page *****/
a {
  cursor: pointer;
}
.loader-container {
  margin:auto auto;
  text-align: center;
  /* width:64px;
  height:64px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -32px;
  margin-left: -32px;
  z-index: 1000; */
}
/*Loader*/
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #dfa937;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 01.1, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

@media screen and (max-width: 991px){
.page.page-inner .page-side.sm-hidden {
    display:none
  }
}

.page.page-inner .page-side .page-side-inner {
  background-image: url('/assets/images/side-bg.jpg');
}

.page.page-inner .page-side .page-side-inner:after {
  background: rgba(247, 238, 249, 0.2);
}

agm-map img {
  max-height: initial;
}

.lang-ar .cart-item{
  padding-left:0 !important;
  /* padding-right:12px !important; */
}

.lang-ar .cart-total{
  padding-left:0 !important;
  /* padding-right:12px !important; */
}

.lang-ar .ar-right{
  float:right;
  text-align:right;
}

.lang-ar .delivery-area{
  padding-bottom: 30px !important;
}

.text-right{
  text-align:left !important;
}

.lang-ar .text-right{
  text-align: right !important;
}
.lang-ar .card-column
{
  float:right !important;
}

.lang-ar .minus-icon{
  border-radius: 0 0 5px 0;
}

.lang-ar .plus-icon{
  border-radius: 0 0 0 5px;
}

.lang-ar .pull-left{
  float: right !important;
}
.footer-copyright{
  position: fixed;
  bottom:0;
  height: 40px;
  width: 620px;
  background-color: white;
  text-align: center;
  padding-top:2px;
  padding-bottom:1px;
}
.page-btn{
  width:620px;
}
@media screen and (max-width: 991px) {
  .footer-copyright{
    width:100%;

  }
  .page-btn{
    width:100%;
  }
}

.lang-ar .reversed-phone{
  display: inline-block;
  direction: ltr;
  text-align: right
}
.owl-dialog-container{
  overflow: visible !important;
}

.swal2-modal{
  background: #393839cc !important;
  color: #fff !important;
}
.swal2-title{
  color:#fff !important;
}
.swal2-content{
  color:#fff !important;
}

.lang-ar .show-btn{
  right: auto !important;
  left:10px !important;
}

.lang-ar .p-r-65{
  padding-left:57px !important;
  padding-right: 10px !important;
}

.lang-ar #sidebar-wrapper {
  margin-right:-220px;
  margin-left: 0;
}

.lang-ar #wrapper.toggled {
  padding-right: 220px;
  padding-left: 0;
}

.lang-ar .sidebar-nav li:before {
  left:auto !important;
  right:0 !important;
}

.lang-ar .text-left{
  text-align: right !important;
}

.lang-ar .menu-alert h1{
  font-size: 2em !important;
}

@media screen and (max-width: 575px) {

.lang-ar .menu-txt{
  margin-right:10px;
  margin-left:0 !important;
}
}

.lang-ar .m-l-20{
  margin: 0 20px 0 0 !important;
}


.lang-ar .m-r-20{
  margin: 0 0 0 20px !important;
}
.lang-ar .margin-to-time{
  margin:0 !important;
}
/* fix cat title */
.cat-title h3{
  margin-top: 1.2rem !important;
}

  /* iphone 3 */
  @media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 1) {
    .cat-title h3{
      font-size: 1rem !important;
    }
  }

  /* iphone 4 */
  @media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2) {
    .cat-title h3{
      font-size: 1.1rem !important;
    }
  }

  /* iphone 5 */
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
    .cat-title h3{
      font-size: 1.1rem !important;
    }
  }

  /* iphone 6, 6s, 7, 8 */
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    .cat-title h3{
      font-size: 1.1rem !important;
    }
   }

  /* iphone 6+, 6s+, 7+, 8+ */
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
    .cat-title h3{
      font-size: 1.1rem !important;
    }
   }

  /* iphone X , XS, 11 Pro */
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .cat-title h3{
      font-size: 1.1rem !important;
    }
   }

  /* iphone XR, 11 */
  @media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) {
    .cat-title h3{
      font-size: 1.1rem !important;
    }
  }

 /* iphone XS Max, 11 Pro Max */
  @media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3) {
    .cat-title h3{
      font-size: 1.1rem !important;
    }
   }

   .page-heading {
     display:none !important;
   }
   .color-white{
     color:white !important;
   }

   @media (min-width: 320px) {
    .cat-main-pic img{
      width:100% !important;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .cat-main-pic img{
      width:70% !important;
    }
  }

.page-sub-container {
    width: 620px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 620px;
            flex: 0 0 620px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative;
    /* @include max($medium) {
        width: 560px;
        flex: 0 0 560px;
      } */
  }

  

@media screen and (max-width: 991px) {
  /* line 15, src/sass/layout/_layout.scss */
  .page-sub-container {
    width: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}